.page {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 10%;
    height: 100%;
    & .user {
      &_container {
        width: 100%;
        display: flex;
        padding: 30px 0;
      }
      &_content {
        padding: 10px 25px;
        font-size: 18px;
        &_item {
          width: 50%;
          border-radius: 20px;
          background: #FFF;
          box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
          padding: 30px;
          margin-right: 25px;
          display: flex;
          position: relative;
          & img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            display: flex;
          }
        }
        &_name {
          font-size: 24px;
          font-weight: 600;
          padding-bottom: 10px;
        }
        &__text {
          font-size: 18px;
          font-weight: 600;
          margin-right: 10px;
        }
        &__social {
          padding-top: 20px;
          display: flex;
          &_item {
            margin-right: 10px;
          }
        }
        &__progress {
          width: 95%;
        }
        &__edit {
          position: absolute;
          top: 10px;
          right: 15px;
          width: 22px;
          height: 22px;
          background: url("/assets/icons/edit_pen.svg") no-repeat center;
          cursor: pointer;
        }
      }
    }
  }
  &__content {
    width: 75%;
  }

}
