.page {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }
  &__title {
    font-size: 40px;
    color: #000;
    font-weight: 700;
    padding-bottom: 20px;
    width: 75%;
  }
  &__users {
    width: 75%;
    display: flex;
    &_item {
      padding: 40px;
      position: relative;
      border-radius: 20px;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
      max-width: 340px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      & .user {
        &__item {
          &_dots {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 24px;
            height: 24px;
            background: url("/assets/icons/dots.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
          }
          &_img {
            &__container {
              width: 144px;
              height: 144px;
              border-radius: 50%;
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
          &_email {
            color: #000;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &_position {
            padding-top: 40px;
            color: #000;

            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        &_team {
          padding: 15px;
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.24px;
          &__icon {
            width: 24px;
            height: 24px;
            background: url("/assets/icons/edit_pen.svg") no-repeat center;
            background-size: contain;
            margin: 15px;
          }
        }

      }
    }
  }
}
