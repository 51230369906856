
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}
.super_admin {
  --main-color: #4274BA;
  --text-color: #000;
  --text-light: #303030;
  --body-color: #ffffff;
  --btn-background: #4274BA;
  --btn-hover: #4274BA;
  --box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
  --background-color: linear-gradient(180deg, #79ABF4 0%, #4274BA 100%);
  --light-bg: #79ABF4;
  --border: 2px solid #4274BA;
}
.admin {
  --main-color: #F2EA33;
  --text-color: #000;
  --text-light: #303030;
  --body-color: #ffffff;
  --btn-background: #F2EA33;
  --btn-hover: #F2EA33;
  --box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
  --background-color: linear-gradient(180deg, #eae381 0%, #F2EA33 100%);
  --light-bg: #eae381;
  --border: 2px solid #F2EA33;
}
.manager {
  --main-color: #80CAA2;
  --text-color: #000;
  --text-light: #303030;
  --body-color: #ffffff;
  --box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
  --background-color: linear-gradient(180deg, #ade2c2 0%, #80CAA2 100%);
  --light-bg: #ade2c2;
}
.coach {
  --main-color: #F1574C;
  --text-color: #000;
  --text-light: #303030;
  --body-color: #ffffff;
  --box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
  --background-color: linear-gradient(180deg, #fa9b94 0%, #F1574C 100%);
  --light-bg: #fa9b94;
}
.collaborator {
  --main-color: #DC3B95;
  --text-color: #000;
  --text-light: #303030;
  --body-color: #ffffff;
  --box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
  --background-color: linear-gradient(180deg, #e787bc 0%, #DC3B95 100%);
  --light-bg: #e787bc;
}
.textDanger {
  color: red;
  font-size: 14px;
}
