.positions {
  &_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 10%;
  }
  &_head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    & a {
      text-decoration: none;
      color: #000;
    }
    &_btn {
      padding: 10px 40px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: var(--box-shadow);
      font-size: 24px;
      letter-spacing: 0.24px;
      display: flex;
      align-items: center;
      border: 2px solid #fff;
      margin: 0 10px;
      position: relative;
      &:hover {
        border: var(--border);
      }
      &:active {
        background: var(--btn-background);
      }
      & input {
        width: 100%;
        height: 56px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #000;

  }
  &__create {
    &_container {
      height: 0;
      opacity: 0;
      visibility: hidden;
      animation-duration: .5s;
      &__open {
        visibility: visible;
        opacity: 1;
        height: auto;
        animation-duration: .5s;
      }

    }
  }
  &__form {
    &_group {
      display: flex;
      flex-direction: column;
      & label {
        color: #303030;
        font-weight: 500;
        padding-bottom: 8px;
      }
      & textarea {
        margin: 15px 0;
        padding: 10px;
        border-radius: 4px;
      }
    }
  }
  &__done {
    &_container {
      width: 100%;
      padding: 60px 0;
      display: flex;
      justify-content: flex-end;
    }
    &_btn {
      padding: 10px 40px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: var(--box-shadow);
      font-size: 24px;
      letter-spacing: 0.24px;
      display: flex;
      align-items: center;
      border: 2px solid #fff;
      margin: 0 10px;
      &:hover {
        border: var(--border);
      }
      &:active {
        background: var(--btn-background);
      }
    }
  }
  &__cancel {
    &_btn {
      padding: 20px 40px;
      margin-right: 20px;
      border: 2px solid #F2EA33;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: var(--box-shadow);
    }
  }
  &__table {
    padding-bottom: 140px;
    &_actions {
      display: flex;
      align-items: center;
    }
    &_edit {
      width: 22px;
      height: 22px;
      background: url("/assets/icons/edit_pen.svg") no-repeat center;
      background-size: contain;
      margin: 0 10px;
      cursor: pointer;
    }
    &_delete {
      width: 22px;
      height: 22px;
      background: url("/assets/icons/trash.svg") no-repeat center;
      background-size: contain;
      margin: 0 10px;
      cursor: pointer;
    }
  }
}
