#spotlight_tabs {
  & .nav-pills {
    & .nav-item {
      & .nav-link {
        font-size: 18px;
        color: #000;
        font-weight: 400;
        &.active {
          background: transparent;
          border-bottom: 2px solid var(--main-color);
          border-radius: 0;
          font-weight: 700;
        }

      }
    }

  }
}
.spotlight {
  &_company {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    &-title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
    }
    &-scores {
      padding: 14px 30px;
      border-radius: 61px;
      border: 2px solid var(--main-color);
      background: var(--background-color);
      color: #230B34;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
      & .scores_value {
        font-weight: 700;

      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 230px;
      &_container {
        padding: 44px 0;
        display: flex;
        justify-content: space-between;
      }
      .progress {
        width: 100%;
        height: 10px;
      }
      .progress-bar {
        width: 100%;
        background: -webkit-linear-gradient(left, #01C5FF 0%,#1A2B88 100%);
      }
      & .progress {
        &_value {
          position: absolute;
          color: #1C1B1F;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          &-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;
            padding-top: 20px;
          }
          &-item {
            color: #79747E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

          }
        }
      }
      &_name {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 30px 0;
      }
      &_icon {
        width: 152px;
        height: 152px;
        &.autonomie {
          background: url("/assets/icons/autonomie.svg");
        }
        &.sens {
          background: url("/assets/icons/sens.svg");
        }
        &.progression {
          background: url("/assets/icons/progression.svg");
        }
        &.reconnaissance {
          background: url("/assets/icons/reconnaissance.svg");
        }
        &.connexion {
          background: url("/assets/icons/connexion.svg");
        }
      }
    }
  }
  &_departments {
    &-content {
      padding: 80px 0 40px;
      & .btn-group {
        padding: 30px 0;
      }
    }
    &-check {
      margin-right: 20px;
      & .btn-check:checked + .btn {
        background-color: #F2EA33 !important;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & label {
        border-radius: 38px;
        background: #ECE9F1;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px 30px;
        border: none;
        &:hover {
          background-color: #ECE9F1 !important;
          color: #000 !important;
        }
      }
    }

  }
  &_card {
    width: 250px;
    height: 140px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    padding: 24px 14px 14px 34px;
    &-container {
      margin-bottom: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      padding-top: 14px;

    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name {
      margin-bottom: 0;
      color: #343434;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;
    }
    &-progress {
      border-radius: 50px;
      background: rgba(35, 193, 10, 0.15);
      padding: 3px 4px;
      color: #0B8A00;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
    &-percentage {
      color: #232323;
      font-size: 45.714px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -2.743px;
      & .percentage_sim {
        color: #232323;
        font-size: 29.714px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.783px;
      }

    }
    &-icon {
      width: 68px;
      height: 68px;
      &.autonomie {
        background: url("/assets/icons/autonomie.svg") no-repeat center;
        background-size: contain;
      }
      &.sens {
        background: url("/assets/icons/sens.svg") no-repeat center;
        background-size: contain;
      }
      &.progression {
        background: url("/assets/icons/progression.svg") no-repeat center;
        background-size: contain;
      }
      &.reconnaissance {
        background: url("/assets/icons/reconnaissance.svg") no-repeat center;
        background-size: contain;
      }
      &.connexion {
        background: url("/assets/icons/connexion.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
  &_table {
    &-container {
      margin-bottom: 150px;
      & table {
        & thead tr{
          border-radius: 5.799px;
          border: 1.16px solid #FFF;
          background: #E9EFF4;
          height: 60px;
          & th {
            background: transparent;
            color: #878790;
            font-size: 15.076px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.302px;
            padding: 20px 10px;
          }
        }
        & tbody {
          & tr {
            height: 60px;
            border-radius: 5.799px;
            border: 1.16px solid #E9EFF4;
            background: var(--white-100, #FFF);
            & td {
              padding: 20px 10px;
              color: #3A3A49;
              font-size: 15.076px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.302px;
              &:first-child {
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

.kpi_tab {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_btn {
      padding: 10px 40px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: var(--box-shadow);
      font-size: 24px;
      letter-spacing: 0.24px;
      display: flex;
      align-items: center;
      border: 2px solid #fff;
      margin: 0 10px;
      &:hover {
        border: var(--border);
      }
      &:active {
        background: var(--btn-background);
      }
    }
  }
  &-title {
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 34px;
    font-weight: 700;
    line-height: normal;
    padding: 60px 0 10px;
  }
  &-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    & .question  {
      &_item {
        position: relative;
        padding: 40px 30px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
        margin: 20px 0;
        &-subtitle {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.24px;
        }
        &-description {
          color: #000;
          font-size: 20px;
          font-weight: 400;
          line-height: normal;
        }
        &-list {
          &_item {
          }
        }
        &-checkbox {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        &-edit {
          position: absolute;
          bottom: 20px;
          right: 20px;
          width: 30px;
          height: 30px;
          margin-bottom: 0;
          border-radius: 50%;
          background: url("/assets/icons/question_edit.svg") no-repeat center;
        }
        input[type=checkbox]:checked {
          accent-color: var(--main-color) !important;
          background-color: var(--main-color) !important;
        }
        input[type=checkbox]{
          accent-color: white !important;
          background-color: white !important;
        }
      }

    }
  }
  &-mail {
    padding-bottom: 120px;
    &_sub {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
    }
    & .send_btn {
      margin-top: 30px;
      padding: 20px 40px;
      margin-right: 20px;
      border: 1px solid var(--main-color);
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: var(--main-color);
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
    & #multiselectContainerReact {
      & ul {
        max-height: 210px;
      }
      & li {
        &.highlightOption {
          background: #DBDBDB;
          color: black;
        }
        &:hover {
          background: #DBDBDB;
          color: black;
        }
      }
      & .chip {
        background: #989898;
        color: black;
        border-radius: 5px;
      }
    }
  }
}
#q_modal {
  & .modal {
    &_body {
      width: 100%;
      padding: 20px 0;
      align-items: start !important;
      & textarea {
        width: inherit;
        resize: none;
        border: 1px solid #535353;
        margin: 5px 0;
        padding: 5px 20px 5px 10px;
        border-radius: 4px;
      }
    }
    &_main {
      &_text {
        font-size: 18px;
      }
      &_question {
        width: 100%;
        &__item {
          position: relative;
          width: 100%;
        }
        &__delete {
          position: absolute;
          right: 5px;
          top: 10px;
          width: 20px;
          height: 20px;
          background: url("/assets/icons/trash.svg") no-repeat center;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    &_btn {
      background: var(--background-color);
      color: var(--text-color);
      width: unset;
      padding: 12px 30px;
      &_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.remove {
        background: #ff3030;
        border-color: #ff3030;
      }
    }
  }
}
