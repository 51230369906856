.page {
  &_head {
    &_btn {
      align-items: center;
      background: #fff;
      border: 2px solid #fff;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
      display: flex;
      font-size: 24px;
      letter-spacing: .24px;
      margin: 0 10px;
      padding: 10px 40px;
      position: relative;
      &__container {
        display: flex;
        padding: 20px 0;
      }
    }
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }
  &__sub {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    padding-bottom: 20px;
    width: 75%;
  }
  &__content {
    width: 75%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    padding: 30px 40px;
    margin-bottom: 100px;
    & ul {
      & li {
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
  &__question {
    &_container {
      padding: 20px 0;
    }
    &_name {
      font-size: 30px;
      font-weight: 700;
    }
    &__name {
      &_active {
        margin-left: 10px;
        display: inline-block;
        transform: rotate(45deg);
        height: 18px;
        width: 12px;
        border-bottom: 5px solid #78b13f;
        border-right: 5px solid #78b13f;
      }
    }
    &_description {
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 20px;
    }
    &_item {
      padding: 5px 5px 30px;
      &__text {
        padding-bottom: 10px;
        font-weight: 500;
      }
      &__btns {
        display: flex;
      }
      &_btn {
        margin: 0 5px;
        padding: 12px 24px;
        border-radius: 4px;
        border: 1px solid rgba(83, 83, 83, 0.10);
        background: #FFF;
        cursor: pointer;
        &:hover {
          border: 1px solid var(--main-color);
        }
      }
      &_point {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 4px;
        border: 1px solid rgba(83, 83, 83, 0.10);
        background: #FFF;
        margin: 5px;
        cursor: pointer;
        &:hover {
          border: 1px solid var(--main-color);
        }
        &__container {
          display: flex;
          align-items: center;
        }
      }
    }
    &_btn {
      padding: 20px 40px;
      border: none;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: var(--main-color);
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
      &:disabled {
        background: #ECEFF1;
      }
      &_container {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.active {
  border: 1px solid var(--main-color);
  color: var(--main-color);;
}
