.page {
  &__content {
    padding: 35px 0;
    max-width: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  &__head {
    &_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_btn {
      border: none;
      margin: 0 15px;
      color: #303030;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      &__container {
        display: flex;
        align-items: center;
      }
      &__delete {
        border: none;
        margin: 0 15px;
        color: #F00;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  &__title {
    font-size: 40px;
    color: #000;
    font-weight: 700;
  }
  &__subtitle {
    margin-bottom: 0;
    color: #303030;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__data {
    &__item {
      display: flex;
    }
    &_block {
      position: relative;
      padding: 30px 0;
      display: flex;
      & form {
        display: flex;
        flex-direction: column;
        width: 75%;
      }
    }
    &_checkbox {
      input[type=radio]:checked {
        accent-color: var(--main-color) !important;
        background-color: var(--main-color) !important;
      }
      input[type=radio]{
        accent-color: white !important;
        background-color: white !important;
        border-color: var(--main-color);
      }
      input[type=radio]:focus {
        box-shadow: 0 0 0 0.1rem var(--main-color);
      }

    }
    &_input {
      &__container {
        padding: 20px 70px;
        width: 100%;
      }
      &__block {
        width: 100%;
        display: flex;
        flex-direction: column;
        & textarea {
          width: inherit;
          resize: none;
          border: 1px solid #535353;
          margin: 5px 0;
          padding: 5px 20px 5px 10px;
          border-radius: 4px;
        }
        & .form_group {
          width: 100%;
        }
        & input,
        & select {
          width: 100%;
          height: 44px;
          border: 1px solid #535353;
          &::placeholder {
            color: #BBB;
            font-weight: 500;
          }
        }
        & select {
          margin: 15px 0;
        }
      }
    }
    &_btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #BBB;
      font-weight: 500;
      cursor: pointer;
      & svg {
        margin-left: 10px;
      }
      &__container {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
    &_scale {
      width: 65px;
      padding: 2px 6px;
      &__container {
        padding: 4px;
      }
    }
  }
  &__team {
    &_container {
      padding-top: 70px;
      & .form_group {
        width: 460px;
        margin: 0 10px;
        & label {
          color: #303030;
          font-weight: 500;
          padding-bottom: 8px;
        }
        & input,
        & select {
          width: 100%;
          height: 44px;
          border: 1px solid #535353;
          &::placeholder {
            color: #BBB;
            font-weight: 500;
          }
        }
      }
    }
    &_btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #BBB;
      font-weight: 500;
      cursor: pointer;
      & svg {
        margin-left: 10px;
      }
      &__container {
        max-width: 460px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
  }
  &__advantage {
    &_content {
      padding-top: 70px;
    }
    &_title {
      color: #303030;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 20px;
    }
    &_blocks {

    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 460px;
      width: 100%;
      padding: 4px 0;
      margin: 10px 0;
      color: #303030;
    }
  }
  &__done {
    &_container {
      width: 100%;
      padding: 60px 0 100px;
      display: flex;
      justify-content: flex-end;
    }
    &_btn {
      padding: 20px 40px;
      border: none;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #ECEFF1;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
  &__cancel {
    &_btn {
      padding: 20px 40px;
      margin-right: 20px;
      border: 1px solid #F2EA33;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
  &__question {
    &_label {
      font-size: 18px;
    }
    &_content {
      width: 100%;
    }
    &__item {
      position: relative;
      width: 100%;
      & textarea {
        width: inherit;
        resize: none;
        border: 1px solid #535353;
        margin: 5px 0;
        padding: 5px 20px 5px 10px;
        border-radius: 4px;
      }
    }
    &__delete {
      position: absolute;
      right: 5px;
      top: 10px;
      width: 20px;
      height: 20px;
      background: url("/assets/icons/trash.svg") no-repeat center;
      background-size: contain;
      cursor: pointer;
    }
  }
}
