.modal {
  &_inside {
    width: 100%;
  }
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 18px;
  }
  &_title {
    color: #303030;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
  &_close {
    width: 32px;
    height: 32px;
    background: url("/assets/icons/close_btn.svg");
    cursor: pointer;
  }
  &_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px 0;
  }
  &_main {
    &_text {
      color: #303030;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }
  }
  &_btn {
    margin: 0 10px;
    border-radius: 30px;
    border: 2px solid var(--light-bg);
    padding: 12px 16px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      color: #000;
      background: var(--light-bg);
      border: none;
    }
    &_container {
      padding-top: 70px;
      display: flex;
      align-items: center;
    }
  }
}
