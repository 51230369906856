.page {
  &__content {
    padding: 35px 0;
    max-width: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  &__head {
    &_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 120px;
    }
    &_btn {
      border: none;
      margin: 0 15px;
      color: #303030;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      &__container {
        display: flex;
        align-items: center;
      }
      &__delete {
        border: none;
        margin: 0 15px;
        color: #F00;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
      }
    }
  }
  &__title {
    font-size: 40px;
    color: #000;
    font-weight: 700;
    margin-bottom: 40px;
  }
  &__subtitle {
    margin-bottom: 0;
    color: #303030;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__data {
    &__item {
      display: flex;
      padding-bottom: 15px;
    }
    &_block {
      position: relative;
      padding: 30px 0;
      display: flex;
      & form {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    &_left {
      width: 200px;
    }
    &_sub {
      margin-bottom: 14px;
    }
    &_img {
      &_cropper {
        position: absolute;
        top: 250px;
        z-index: 2;
        &__btn {
          padding: 5px 20px;
          border-radius: 10px;
          background: #FFF;
          box-shadow: var(--box-shadow);
          font-size: 20px;
          display: flex;
          align-items: center;
          border: 2px solid #fff;
          margin: 0 10px;
          position: relative;
          &:hover {
            border: var(--border);
          }
          &:active {
            background: var(--btn-background);
          }
        }
        &__content {
          padding: 10px 0;
          width: 440px;
          height: 260px;
        }
        &_block {
          padding: 10px 0;
          display: flex;
        }
      }
      &_selected {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #01C5FF;
      }
      &_changed {
        position: absolute;
        top: 0;
        left: 0;
        background: #FFFFFF;
      }
      &__container {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        position: relative;
        background: #01C5FF;
        & img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      &__icon {
        width: 42px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #01C5FF;
        background-image: url("/assets/icons/camera_icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0;
        right: 20px;
        z-index: 2;
        cursor: pointer;
        & input {
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    &_input {
      &__container {
        padding: 0 60px;
        width: 80%;
      }
      &__block {
        width: 100%;
        display: flex;
        padding-bottom: 30px;
        & .form_group {
          width: 460px;
          margin: 0 10px;
          & label {
            color: #303030;
            font-weight: 500;
            padding-bottom: 8px;
          }
        }
        & input,
        & select {
          width: 100%;
          height: 44px;
          border: 1px solid #535353;
          &::placeholder {
            color: #BBB;
            font-weight: 500;
          }
        }
        & select {
          margin: 15px 0;
        }
      }
    }
    &_select {
      border: none !important;
      margin-top: 15px;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      & option {
        padding-bottom: 5px;
        border-bottom: 1px solid grey;
        text-align: center;
        background: transparent;
      }
      &_item {
        display: flex;
        flex-direction: column;
        align-items: end;
      }

    }
  }
  &__admins {
    &_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &_cards {
      width: 75%;
      display: flex;
      flex-wrap: wrap;
    }
    &_card {
      max-width: 205px;
      width: 100%;
      border-radius: 20px;
      background: #FFF;
      box-shadow: 2px 4px 8px 0 rgba(50, 50, 71, 0.08), 0 8px 16px 0 rgba(50, 50, 71, 0.06);
      position: relative;
      margin: 10px;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__dots {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background: url("/assets/icons/dots.svg") no-repeat center;
        cursor: pointer;
      }
      &__img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #0dcaf0;
        & img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      &__name {
        padding: 20px 0;
        color: #000;
        font-weight: 700;
        line-height: normal;
        text-align: center;
      }
    }
    &_btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #BBB;
      font-weight: 500;
      cursor: pointer;
      & svg {
        margin-left: 10px;
      }
      &__container {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
  }

  &__done {
    &_container {
      width: 100%;
      padding: 10px 0 120px;
      display: flex;
      justify-content: flex-end;
    }
    &_btn {
      padding: 20px 40px;
      margin-right: 20px;
      border: none;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #4274BA;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
  &__cancel {
    &_btn {
      padding: 20px 40px;
      margin-right: 20px;
      border: 1px solid #4274BA;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
