.page {
  &__content {
    padding: 35px 0;
    max-width: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  &__head {
    &_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 120px;
    }
    &_btn {
      border: none;
      margin: 0 15px;
      color: #303030;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      &__container {
        display: flex;
        align-items: center;
      }
      &__delete {
        border: none;
        margin: 0 15px;
        color: #F00;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
      }
    }
  }
  &__title {
    font-size: 40px;
    color: #000;
    font-weight: 700;
  }
  &__data {
    &_block {
      position: relative;
      padding: 30px 0 80px;
      display: flex;
      & form {
        display: flex;
      }
    }
    &_img {
      &_cropper {
        position: absolute;
        top: 220px;
        &__btn {
          padding: 5px 20px;
          border-radius: 10px;
          background: #FFF;
          box-shadow: var(--box-shadow);
          font-size: 20px;
          display: flex;
          align-items: center;
          border: 2px solid #fff;
          margin: 0 10px;
          position: relative;
          &:hover {
            border: var(--border);
          }
          &:active {
            background: var(--btn-background);
          }
        }
        &__content {
          padding: 10px 0;
          width: 440px;
          height: 260px;
        }
        &_block {
          padding: 10px 0;
          display: flex;
        }
      }
      &_selected {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #01C5FF;
      }
      &_changed {
        position: absolute;
        top: 0;
        left: 0;
        background: #FFFFFF;
      }
      &__container {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        position: relative;
        background: #01C5FF;
        & img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
        }
      }
      &__icon {
        width: 42px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #01C5FF;
        background-image: url("/assets/icons/camera_icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0;
        right: 20px;
        z-index: 2;
        cursor: pointer;
        & input {
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    &_input {
      &__container {
        padding: 20px 70px;
      }
      &__block {
        width: 100%;
        display: flex;
        padding-top: 30px;
        & .form_group {
          width: 460px;
          margin: 0 10px;
          &__container {

          }
          &_team {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0;
            &__name {
              font-weight: 600;
            }
            &_delete {
              width: 22px;
              height: 22px;
              background: url("/assets/icons/trash.svg") no-repeat center;
              background-size: contain;
              margin: 0 10px;
              cursor: pointer;

            }
          }
          & label {
            color: #303030;
            font-weight: 500;
            padding-bottom: 8px;
          }
        }
        & input,
        & select {
          width: 100%;
          height: 44px;
          border: 1px solid #535353;
          &::placeholder {
            color: #BBB;
            font-weight: 500;
          }
        }
        & select {
          margin: 15px 0;
        }
      }
    }
  }
  &__team {
    &_container {
      padding-top: 70px;
      & .form_group {
        width: 460px;
        margin: 0 10px;
        & label {
          color: #303030;
          font-weight: 500;
          padding-bottom: 8px;
        }
        & input,
        & select {
          width: 100%;
          height: 44px;
          border: 1px solid #535353;
          &::placeholder {
            color: #BBB;
            font-weight: 500;
          }
        }
      }
    }
    &_btn {
      display: flex;
      align-items: center;
      padding: 5px 0;
      color: #BBB;
      font-weight: 500;
      cursor: pointer;
      & svg {
        margin-left: 10px;
      }
      &__container {
        max-width: 460px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
      }
    }
  }
  &__advantage {
    &_content {
      padding-top: 70px;
    }
    &_title {
      color: #303030;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 20px;
    }
    &_blocks {

    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 460px;
      width: 100%;
      padding: 4px 0;
      margin: 10px 0;
      color: #303030;
    }
  }
  &__done {
    &_container {
      width: 100%;
      padding: 60px 0;
      display: flex;
      justify-content: flex-end;
    }
    &_btn {
      padding: 20px 40px;
      border: none;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #ECEFF1;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
  &__cancel {
    &_btn {
      padding: 20px 40px;
      margin-right: 20px;
      border: 1px solid #F2EA33;
      color: #000;
      font-size: 24px;
      letter-spacing: 0.24px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    }
  }
}
