
.page {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }
  &__sub {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    padding-bottom: 20px;
    width: 75%;
  }
  &__content {
    width: 75%;
  }
}
