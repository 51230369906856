.sign {
  &_container {
    padding-top: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &_content {
    max-width: 1000px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &_title {
    color: #020000;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 40px;
  }
  &_form {
    max-width: 370px;
    width: 100%;
    padding-top: 40px;
  }

  &_input {
    &_item {
      width: 100%;
      padding: 20px 0;
    }
  }

  &_btn {
    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0 0;
      & .btn_fill {
        padding: 10px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1C1B1F;
        color: #F3F3F3;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 0;
        border: none;
      }
    }
  }
  &_text {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 50px;
    &_link {
      color: #479CFF;
    }
  }
}

