#spotlight_tab {
  & .nav-pills {
    & .nav-item {
      & .nav-link {
        font-size: 18px;
        color: #000;
        font-weight: 400;
        &.active {
          background: transparent;
          border-bottom: 2px solid #DC3B95;
          border-radius: 0;
          font-weight: 700;
        }

      }
    }

  }
  .user {
    &_container {
      display: flex;
      padding: 30px 0;
    }
    &_content {
      padding: 10px 25px;
      font-size: 18px;
      &_item {
        width: 50%;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
        padding: 30px;
        margin-right: 25px;
        display: flex;
        & img {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          display: flex;
        }

      }
      &_name {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 10px;
      }
      &__text {
        font-size: 18px;
        font-weight: 600;
        margin-right: 10px;
      }
      &__social {
        padding-top: 20px;
        display: flex;
        &_item {
          margin-right: 10px;
        }
      }
      &__progress {
        width: 95%;
      }
    }

  }

}
.spotlight {
  &_company {
    &-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 230px;
      &_container {
        padding: 44px 0;
        display: flex;
        justify-content: space-between;
      }
      .progress {
        width: 100%;
        height: 10px;
      }
      .progress-bar {
        width: 100%;
        background: -webkit-linear-gradient(left, #01C5FF 0%,#1A2B88 100%);
      }
      & .progress {
        &_value {
          position: absolute;
          color: #1C1B1F;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          &-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;
            padding-top: 20px;
          }
          &-item {
            color: #79747E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

          }
        }
      }
      &_name {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 30px 0;
      }
      &_icon {
        width: 152px;
        height: 152px;
        &.autonomie {
          background: url("/assets/icons/autonomie.svg");
        }
        &.sens {
          background: url("/assets/icons/sens.svg");
        }
        &.progression {
          background: url("/assets/icons/progression.svg");
        }
        &.reconnaissance {
          background: url("/assets/icons/reconnaissance.svg");
        }
        &.connexion {
          background: url("/assets/icons/connexion.svg");
        }
      }
    }
  }
}
