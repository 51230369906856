.departments {
  &_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 10%;
    & .btn__container {
      display: flex;
      align-items: center;
      & .main_btn {
        margin: 20px;
        padding: 20px 40px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: var(--box-shadow);
        font-size: 24px;
        letter-spacing: 0.24px;
        display: flex;
        align-items: center;
        border: 2px solid #fff;
        position: relative;
        &__edit_icon {
          width: 24px;
          height: 24px;
          background: url("/assets/icons/edit_pen.svg");
          display: flex;
          margin-right: 10px;
        }
        &__paper_icon {
          width: 24px;
          height: 24px;
          background: url("/assets/icons/paper_arrow.svg");
          display: flex;
          margin-right: 10px;
        }
        &:hover {
          border: var(--border);
        }
        &:active {
          background: var(--btn-background);
        }
        & input {
          width: 100%;
          height: 56px;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
      }
    }
  }
  &_head {
    display: flex;
    justify-content: space-between;
    & a {
      text-decoration: none;
      color: #000;
    }
    &_btn {
      padding: 10px 40px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: var(--box-shadow);
      font-size: 24px;
      letter-spacing: 0.24px;
      display: flex;
      align-items: center;
      border: 2px solid #fff;
      margin: 0 10px;
      position: relative;
      &:hover {
        border: var(--border);
      }
      &:active {
        background: var(--btn-background);
      }
      & input {
        width: 100%;
        height: 56px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #000;
  }
  &__subtitle {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }
  &__main_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15%;
  }
  &__main {
    &_container {
      padding: 40px 0 100px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__users {
    width: 75%;
    display: flex;
    &_item {
      padding: 40px;
      position: relative;
      border-radius: 20px;
      box-shadow: 2px 4px 8px 0 rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
      max-width: 340px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px;
      cursor: pointer;
      & .user {
        &__item {
          &_img {
            &__container {
              width: 144px;
              height: 144px;
              border-radius: 50%;
              border: 1px solid #0dcaf0;
              & img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
              }
            }
            &_empty {
              width: 100%;
              height: 100%;
              background: #0dcaf0;
            }
          }
          &_email {
            color: #000;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &_position {
            padding-top: 40px;
            color: #000;

            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        &_team {
          padding: 15px;
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.24px;
          &__icon {
            width: 24px;
            height: 24px;
            background: url("/assets/icons/edit_pen.svg") no-repeat center;
            background-size: contain;
            margin: 15px;
          }
        }

      }
    }
  }


}

