#m_modal {
  & .modal {
    &_body {
      width: 100%;
      padding: 20px 0;
      align-items: start !important;
      & textarea {
        width: inherit;
        resize: none;
        border: 1px solid #535353;
        margin: 5px 0;
        padding: 5px 20px 5px 10px;
        border-radius: 4px;
      }
    }
    &_main {
      &_text {
        font-size: 18px;
      }
      &_question {
        width: 100%;
        &__item {
          position: relative;
          width: 100%;
        }
        &__delete {
          position: absolute;
          right: 5px;
          top: 10px;
          width: 20px;
          height: 20px;
          background: url("/assets/icons/trash.svg") no-repeat center;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    &_btn {
      background: var(--background-color);
      color: var(--text-color);
      width: unset;
      padding: 12px 30px;
      &_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.remove {
        background: #ff3030;
        border-color: #ff3030;
      }
    }
  }
}
