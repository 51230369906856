.nav-link.active {
  background-color: #ff5733 !important; /* Change the background color */
  color: white;              /* Change the text color */
  font-weight: bold;          /* Make text bold */
}
.nav-pills {
  background: red;
  color: red;
}
.page {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
  }
  &__title {
    font-size: 40px;
    color: #000;
    font-weight: 700;
    padding-bottom: 20px;
    width: 75%;
  }
  &__content {
    width: 75%;
  }
}
