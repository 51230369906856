.top {
  &_bar {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-color);
    color: #000000;
    padding: 0 100px;
    z-index: 10;
    &__logo {
      width: 162px;
      height: 62px;
      background: url("/assets/icons/header_logo.svg") no-repeat center;
      background-size: contain;
      margin: 0 80px;
    }
    &__menu {
      &_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &_item {
        color: #000;
        font-size: 18px;
        margin: 0 26px;
        & .dropdown {
          background: transparent;
          & button {
            background: transparent;
          }
        }
      }
    }
    &__dropdown {
      & ul {
        border: none;
        background: var(--main-color);
        inset: auto !important;
        right: 10px !important;
        z-index: 1;
        & li {
          text-align: right;
          font-size: 18px;
          color: #000;
          cursor: pointer;
          &:hover {
            background: var(--background-color);
          }
          &:active {
            color: #000;
          }
        }
      }
    }
    &__dropdown button{
      background: transparent;
      color: #000;
      font-size: 18px;
      font-weight: 600;
      border: none;
      margin: 0 26px;
    }
    &__user_logo {
      width: 70px;
      height: 70px;
      background: url("/assets/icons/user_logo.svg") no-repeat center;
      position: relative;
      & img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 8px;
        background: var(--main-color);
        z-index: 3;
      }
      & span {
        position: absolute;
        right: 0;
        white-space: nowrap;
        transform: translateY(45px);
        transition: .5s;
        visibility: hidden;
        opacity: 0;
        font-weight: 600;
        z-index: 2;
      }
      &:hover {
        & span {
          transform: translateY(60px);
          transition: .5s;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &__bell_logo {
      width: 26px;
      height: 26px;
      background: url("/assets/icons/bell_icon.svg") no-repeat center;
      margin: 0 20px;
      position: relative;
      &_link {
        display: block;
        width: 26px;
        height: 26px;
      }
      &_count {
        position: absolute;
        top: -2px;
        right: -4px;
        font-size: 11px;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_collapse {
        position: absolute;
        width: 500px;
        right: 0;
        top: 35px;
        & a {
          position: absolute;
          right: 10px;
          top: 4px;
          z-index: 1;
          text-decoration: none;
          color: black;
        }
      }
      &_list {
        overflow: auto;
        overflow-y: scroll;
        max-height: 600px;
        padding-left: 5px;
        padding-right: 10px;
        margin-top: 10px;
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 6px;
          background: #efefef;
        }
        &::-webkit-scrollbar-thumb {
          background: #696969;
          border-radius: 6px;
        }
        &__item {
          font-size: 14px;
          font-weight: 500;
          padding: 10px 20px;
          border-bottom: 1px solid #cccccc;
          border-radius: 4px;
          background: aliceblue;
          margin: 10px 0;
          list-style-type: none;
          position: relative;
          box-shadow: -2px 2px 5px 0 rgba(50, 50, 71, 0.4);
          cursor: pointer;
          &_read {
            background: transparent;
            box-shadow: none;
            font-weight: 300;
            color: #717171;
          }
        }
        &_delete {
          font-size: 10px;
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid grey;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #8f8f8f;
          color: #ffffff;
          font-weight: 600;
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      margin: 0 100px;
      & .input {
        &__icon {
          position: absolute;
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          background: url("/assets/icons/loop.svg") no-repeat center;
        }
        &__container {
          position: relative;
          & input {
            width: 220px;
            height: 30px;
            background: #fff;
            border-radius: 60px;
            border: none;
            padding: 4px 34px;
          }
        }
      }

    }
    &__link {
      color: #000;
      font-size: 18px;
      font-weight: 600;
      margin: 0 26px;
      cursor: pointer;
    }
  }
}

