.footer {
  &__container {
    width: 100%;
    height: 100px;
    background: var(--main-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 2;
  }
  &__content {
    max-width: 75%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    width: 240px;
    height: 70px;
    background: url("/assets/icons/footer_logo.svg") no-repeat center;
    background-size: contain;
  }
  &__touch {
    &_container {
      position: relative;
      background: transparent;
      display: flex;
      flex-direction: column;
    }
    &_follow {
      color: #FFF;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 10px 0;
      display: flex;
      align-items: center;
    }
    &_youtube {
      width: 50px;
      height: 36px;
      margin: 0 10px;
      background: url("/assets/icons/footer_youtube_icon.svg") no-repeat center;

    }
    &_linkedin {
      width: 38px;
      height: 38px;
      margin: 0 10px;
      background: url("/assets/icons/footer_linkedin_icon.svg") no-repeat center;
    }
    &_x {
      width: 54px;
      height: 54px;
      margin: 0 10px;
      background: url("/assets/icons/footer_x_icon.svg") no-repeat center;
    }
  }

}
