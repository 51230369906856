#spotlight_tabs {
  & .nav-pills {
    & .nav-item {
      & .nav-link {
        font-size: 18px;
        color: #000;
        font-weight: 400;
        &.active {
          background: transparent;
          border-bottom: 2px solid var(--main-color);
          border-radius: 0;
          font-weight: 700;
        }

      }
    }

  }
}
.spotlight {
  &_company {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    &-title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
    }
    &-scores {
      padding: 14px 30px;
      border-radius: 61px;
      border: 2px solid var(--main-color);
      background: var(--background-color);
      color: #230B34;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
      & .scores_value {
        font-weight: 700;

      }
    }
    &-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 230px;
      &_container {
        padding: 44px 0;
        display: flex;
        justify-content: space-between;
      }
      .progress {
        width: 100%;
        height: 10px;
      }
      .progress-bar {
        width: 100%;
        background: -webkit-linear-gradient(left, #01C5FF 0%,#1A2B88 100%);
      }
      & .progress {
        &_value {
          position: absolute;
          color: #1C1B1F;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          &-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: relative;
            padding-top: 20px;
          }
          &-item {
            color: #79747E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

          }
        }
      }
      &_name {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 30px 0;
      }
      &_icon {
        width: 152px;
        height: 152px;
        &.autonomie {
          background: url("/assets/icons/autonomie.svg");
        }
        &.sens {
          background: url("/assets/icons/sens.svg");
        }
        &.progression {
          background: url("/assets/icons/progression.svg");
        }
        &.reconnaissance {
          background: url("/assets/icons/reconnaissance.svg");
        }
        &.connexion {
          background: url("/assets/icons/connexion.svg");
        }
      }
    }
  }
  &_departments {
    &-content {
      padding: 80px 0 40px;
      & .btn-group {
        padding: 30px 0;
      }
    }
    &-check {
      margin-right: 20px;
      & .btn-check:checked + .btn {
        background-color: var(--main-color) !important;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      & label {
        border-radius: 38px;
        background: #ECE9F1;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 10px 30px;
        border: none;
        &:hover {
          background-color: #ECE9F1 !important;
          color: #000 !important;
        }
      }
    }

  }
  &_card {
    width: 250px;
    height: 140px;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 2px 4px 8px 0px rgba(50, 50, 71, 0.08), 0px 8px 16px 0px rgba(50, 50, 71, 0.06);
    padding: 24px 14px 14px 34px;
    &-container {
      margin-bottom: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      padding-top: 14px;

    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-name {
      margin-bottom: 0;
      color: #343434;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;
    }
    &-progress {
      border-radius: 50px;
      background: rgba(35, 193, 10, 0.15);
      padding: 3px 4px;
      color: #0B8A00;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
    &-percentage {
      color: #232323;
      font-size: 45.714px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -2.743px;
      & .percentage_sim {
        color: #232323;
        font-size: 29.714px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1.783px;
      }

    }
    &-icon {
      width: 68px;
      height: 68px;
      &.autonomie {
        background: url("/assets/icons/autonomie.svg") no-repeat center;
        background-size: contain;
      }
      &.sens {
        background: url("/assets/icons/sens.svg") no-repeat center;
        background-size: contain;
      }
      &.progression {
        background: url("/assets/icons/progression.svg") no-repeat center;
        background-size: contain;
      }
      &.reconnaissance {
        background: url("/assets/icons/reconnaissance.svg") no-repeat center;
        background-size: contain;
      }
      &.connexion {
        background: url("/assets/icons/connexion.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
  &_table {
    &-container {
      margin-bottom: 150px;
      & table {
        & thead tr{
          border-radius: 5.799px;
          border: 1.16px solid #FFF;
          background: #E9EFF4;
          height: 60px;
          & th {
            background: transparent;
            color: #878790;
            font-size: 15.076px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.302px;
            padding: 20px 10px;
          }
        }
        & tbody {
          & tr {
            height: 60px;
            border-radius: 5.799px;
            border: 1.16px solid #E9EFF4;
            background: var(--white-100, #FFF);
            & td {
              padding: 20px 10px;
              color: #3A3A49;
              font-size: 15.076px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.302px;
              &:first-child {
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
